import { ExchangeBetSide, ExtendedExchangeBet } from '../../../../types';
import { StatusMapParams } from '../../../common/hooks/useBetfairExchangeSizeStatusMap';
import getExchangeBetStatus from '../../../common/getExchangeBetStatus';
import { ExchangeBetStatus } from '../../../common/hooks/useExtendedExchangeBetStatusMap';
import calculateMatchedStake from '../../../common/calculateMatchedStake';

export type ExchangeBetOverviewListRow = {
  id: string;
  date: string;
  side: ExchangeBetSide;
  settled: string;
  eventType: string | undefined;
  event: string;
  marketName: string;
  price: number;
  status: ExchangeBetStatus;
  avp: number;
  priceReduce: number;
  size: number;
  playerId: string;
  sizeType: string;
  outcome: string;
  marketId?: string;
};

interface NormalizeBetListDataParams {
  exchangeBets: ExtendedExchangeBet[];
  exchangeSizeStatusMap: Record<ExchangeBetStatus, StatusMapParams>;
}

const normalizeBetListData = (
  params: NormalizeBetListDataParams,
): ExchangeBetOverviewListRow[] => {
  const { exchangeBets, exchangeSizeStatusMap } = params;

  const betDataList = exchangeBets.map((bet) => {
    const {
      id,
      player_id,
      price,
      size,
      side,
      betfairExchangeBet,
      settled_at,
      status,
      selection_name,
      market_name,
      price_reduce,
      handicap,
      market_id,
      event_open_date,
    } = bet;

    const { placed_at, event_name, event_type_name, average_price } =
      betfairExchangeBet;

    // console.log(betfairExchangeBet);

    const sizeType = getExchangeBetStatus({ exchangeBet: bet });

    const settledAt = sizeType === 'settled' ? settled_at || '' : '-';

    const sizeStatusMapItem = exchangeSizeStatusMap[sizeType];

    const marketName = `${market_name} ${handicap || ''}`;

    const matchedSize = calculateMatchedStake({ exchangeBet: bet });

    return {
      id,
      date: placed_at,
      side,
      settled: settledAt,
      eventType: event_type_name,
      event: event_name || '',
      eventOpenDate: event_open_date,
      marketName,
      price,
      avp: average_price,
      size,
      matchedSize,
      status,
      playerId: player_id,
      priceReduce: price_reduce,
      sizeType: sizeStatusMapItem.label,
      outcome: selection_name,
      marketId: market_id,
    };
  });

  return betDataList;
};

export default normalizeBetListData;
