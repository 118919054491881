import React from 'react';
import {
  GridRenderCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import InputCell from '../InputCell';

const PriorityEditCell = (props: GridRenderCellParams<number>): JSX.Element => {
  const { value, id, field } = props;

  const apiRef = useGridApiContext();

  const onChangeInputCell = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { target } = event;

    await apiRef.current.setEditCellValue({
      id,
      field,
      value: Number(target.value),
    });
  };

  const onBlurInputCell = () => {
    apiRef.current.commitCellChange({ id, field });
    apiRef.current.setCellMode(id, field, 'view');
  };

  return (
    <InputCell
      type="number"
      defaultValue={value}
      inputProps={{ min: 0 }}
      onChange={onChangeInputCell}
      onBlur={onBlurInputCell}
    />
  );
};

export default PriorityEditCell;
