import { defineMessages } from 'react-intl';

export default defineMessages({
  sport: {
    id: 'Unknown.SportLineList.sport',
    defaultMessage: 'Sport',
  },
  fromLabel: {
    id: 'Unknown.SportLineList.fromLabel',
    defaultMessage: 'From',
  },
  toLabel: {
    id: 'Unknown.SportLineList.toLabel',
    defaultMessage: 'To',
  },
  status: {
    id: 'Unknown.SportLineList.status',
    defaultMessage: 'Status',
  },
  enable: {
    id: 'SportLine.enable',
    defaultMessage: 'Enable',
  },
  riskLevel: {
    id: 'SportLine.riskLevel',
    defaultMessage: 'Risk Level',
  },
  priority: {
    id: 'SportLine.priority',
    defaultMessage: 'Priority',
  },
  errorMessage: {
    id: 'Unknown.SportLineList.errorMessage',
    defaultMessage: 'Error while trying to upload sportline.',
  },
  successUpdate: {
    id: 'SportLine.successUpdate',
    defaultMessage: 'Setting successfully updated!',
  },
  errorUpdate: {
    id: 'SportLine.errorUpdate',
    defaultMessage: 'Failed to update priority. Please try again.',
  },
  unknownType: {
    id: 'Exchange.ExchangeSettingSportline.unknownType',
    defaultMessage: 'Unknown exchange type',
  },
  loadMore: {
    id: 'Exchange.ExchangeSettingSportline.loadMore',
    defaultMessage: 'Load more',
  },
  duplicatePriority: {
    id: 'SportLine.duplicatePriority',
    defaultMessage: 'This priority value already exists for another sport line',
  },
  noNegativeValues: {
    id: 'SportLine.noNegativeValues',
    defaultMessage: 'Negative values are not allowed',
  },
  noPriorityChange: {
    id: 'SportLine.noPriorityChange',
    defaultMessage: 'No change in priority value',
  },
  successExchangeUpdate: {
    id: 'Exchange.ExchangeSettingSportline.successUpdate',
    defaultMessage:
      'Exchange configuration successfully updated, updates will be applied within 1 hour',
  },
  errorExchangeUpdate: {
    id: 'Exchange.ExchangeSettingSportline.errorUpdate',
    defaultMessage: 'Error while trying update exchange config',
  },
});
