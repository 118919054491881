import useSupabase from '../../../common/hooks/useSupabase';
import { ExchangeConfig } from '../../../../types/supabase';

const SELECT_EXCHANGE_CONFIGS = `*`;

type GetExchangeConfigsParams = {
  refs: string[];
  brandId: string;
  authUserId?: string;
  isSystemAdmin?: boolean;
  playerId?: string;
};

export const fetchExchangeConfigs = async (
  supabase: ReturnType<typeof useSupabase>,
  params: GetExchangeConfigsParams,
): Promise<Map<string | number, ExchangeConfig>> => {
  const { refs, brandId, isSystemAdmin, playerId, authUserId } = params;

  let hierarchyIds: (string | undefined)[] = [];
  let parentIds: string[] = [];

  if (authUserId) {
    const { data: backofficeUser } = await supabase
      .from('backoffice_users')
      .select('id, parent_path')
      .eq('id', authUserId)
      .single();
    parentIds = backofficeUser?.parent_path?.split('/') || [];

    if (isSystemAdmin) {
      hierarchyIds = ['systemAdmin'];
    } else {
      hierarchyIds = ['systemAdmin', ...parentIds, authUserId];
    }
  }

  const brandConfigPromise = supabase
    .from('exchange_configs')
    .select(SELECT_EXCHANGE_CONFIGS)
    .in('ref', refs)
    .eq('brand_id', brandId);

  const playerConfigPromise = playerId
    ? supabase
        .from('exchange_configs')
        .select<typeof SELECT_EXCHANGE_CONFIGS, ExchangeConfig>(
          SELECT_EXCHANGE_CONFIGS,
        )
        .eq('brand_id', brandId)
        .eq('player_id', playerId)
    : Promise.resolve(null);

  const [brandResult, playerResult] = await Promise.all([
    brandConfigPromise,
    playerConfigPromise,
  ]);

  const configs: ExchangeConfig[] = [];

  if (brandResult.data) {
    configs.push(...brandResult.data);
  }

  if (playerResult?.data) {
    configs.push(...playerResult.data);
  }

  const configMap = new Map<string | number, ExchangeConfig>();

  let filteredConfigs = configs;

  if (authUserId) {
    filteredConfigs = configs?.filter((config) => {
      const isLegacyConfig = config.disabled_by == null;
      const isPlayerConfig = config.player_id === playerId;
      return (
        hierarchyIds.includes(config.disabled_by ?? '') ||
        isLegacyConfig ||
        isPlayerConfig
      );
    });

    const sortedConfigs = filteredConfigs?.sort((a, b) => {
      const indexA: number = hierarchyIds?.indexOf(a.disabled_by ?? '') ?? -1;
      const indexB: number = hierarchyIds?.indexOf(b.disabled_by ?? '') ?? -1;
      return indexA - indexB;
    });

    sortedConfigs?.forEach((config) => {
      const { ref, disabled_by, is_disabled, player_id } = config;
      const existedConfig = configMap.get(ref);
      const isOwnedConfig = disabled_by === authUserId;
      const isDisabledByParentOrOwnedConfig =
        !existedConfig && (is_disabled || isSystemAdmin || isOwnedConfig);
      const isPlayerConfig = playerId != null && player_id === playerId;
      const isBrandConfig = player_id == null;
      const isExistedConfigNotDisabled = !existedConfig?.is_disabled;
      const isExistedConfigPlayer = existedConfig?.player_id;

      if (isDisabledByParentOrOwnedConfig) {
        configMap.set(ref, config as ExchangeConfig);
      } else if (
        isExistedConfigNotDisabled &&
        isPlayerConfig &&
        isOwnedConfig
      ) {
        configMap.set(ref, config as ExchangeConfig);
      } else if (isExistedConfigPlayer && isBrandConfig && is_disabled) {
        configMap.set(ref, config as ExchangeConfig);
      }
    });
  } else {
    // Handle priority-based sorting for event types
    const sortedConfigs = filteredConfigs?.sort((a, b) => {
      return (b.priority || 0) - (a.priority || 0);
    });

    sortedConfigs?.forEach((config) => {
      const { ref } = config;
      const existedConfig = configMap.get(ref);

      if (
        !existedConfig ||
        (config.priority || 0) > (existedConfig.priority || 0)
      ) {
        configMap.set(ref, config as ExchangeConfig);
      }
    });
  }

  return configMap;
};
