import { defineMessages } from 'react-intl';

export default defineMessages({
  dashboard: {
    id: 'Unknown.AppNavigation.dashboard',
    defaultMessage: 'Dashboard',
  },
  playersAndAgents: {
    id: 'Unknown.AppNavigation.playersAndAgents',
    defaultMessage: 'Players & Agents',
  },
  playerOverview: {
    id: 'Unknown.AppNavigation.playerOverview',
    defaultMessage: 'Player overview',
  },
  agentOverview: {
    id: 'Unknown.AppNavigation.agentOverview',
    defaultMessage: 'Agent overview',
  },
  duplicatePlayers: {
    id: 'Unknown.AppNavigation.duplicatePlayers',
    defaultMessage: 'Duplicate players',
  },
  internalization: {
    id: 'Unknown.AppNavigation.internalization',
    defaultMessage: 'Internalization',
  },
  manualRegistration: {
    id: 'Unknown.AppNavigation.manualRegistration',
    defaultMessage: 'Manual registration',
  },
  exchange: {
    id: 'Unknown.AppNavigation.exchange',
    defaultMessage: 'Exchange',
  },
  bettingOverview: {
    id: 'Unknown.AppNavigation.bettingOverview',
    defaultMessage: 'Betting overview',
  },
  betExport: {
    id: 'Unknown.AppNavigation.betExport',
    defaultMessage: 'Bet export',
  },
  eventOverview: {
    id: 'Unknown.AppNavigation.eventOverview',
    defaultMessage: 'Event overview',
  },
  pastEvents: {
    id: 'Unknown.AppNavigation.pastEvents',
    defaultMessage: 'Past events',
  },
  exchangeSettings: {
    id: 'Unknown.AppNavigation.exchangeSettings',
    defaultMessage: 'Exchange settings',
  },
  marketActions: {
    id: 'Unknown.AppNavigation.marketActions',
    defaultMessage: 'Market actions',
  },
  riskBets: {
    id: 'Unknown.AppNavigation.riskBets',
    defaultMessage: 'Risk bets',
  },
  casino: {
    id: 'Unknown.AppNavigation.casino',
    defaultMessage: 'Casino',
  },
  casinoOverview: {
    id: 'Unknown.AppNavigation.casinoOverview',
    defaultMessage: 'Casino overview',
  },
  casinoGames: {
    id: 'Unknown.AppNavigation.casinoGames',
    defaultMessage: 'Casino games',
  },
  casinoSettings: {
    id: 'Unknown.AppNavigation.casinoSettings',
    defaultMessage: 'Casino settings',
  },
  reports: {
    id: 'Unknown.AppNavigation.reports',
    defaultMessage: 'Reports',
  },
  PLReport: {
    id: 'Unknown.AppNavigation.PLReport',
    defaultMessage: 'P/L report',
  },
  PLReportDetailed: {
    id: 'Unknown.AppNavigation.PLReportDetailed',
    defaultMessage: 'Detailed P/L report',
  },
  forecastReport: {
    id: 'Unknown.AppNavigation.forecastReport',
    defaultMessage: 'Forecast report',
  },
  settlementReport: {
    id: 'Unknown.AppNavigation.settlementReport',
    defaultMessage: 'Settlement report',
  },
  bettingSlipReport: {
    id: 'Unknown.AppNavigation.bettingSlipReport',
    defaultMessage: 'Betting slip report',
  },
  userReport: {
    id: 'Unknown.AppNavigation.userReport',
    defaultMessage: 'User report',
  },
  cms: {
    id: 'Unknown.AppNavigation.cms',
    defaultMessage: 'CMS',
  },
  themeSettings: {
    id: 'Unknown.AppNavigation.themeSettings',
    defaultMessage: 'Theme settings',
  },
  widgets: {
    id: 'Unknown.AppNavigation.widgets',
    defaultMessage: 'Widgets',
  },
  support: {
    id: 'Unknown.AppNavigation.support',
    defaultMessage: 'Support',
  },
  ticketOverview: {
    id: 'Unknown.AppNavigation.ticketOverview',
    defaultMessage: 'Ticket overview',
  },
  system: {
    id: 'Unknown.AppNavigation.system',
    defaultMessage: 'System',
  },
  brandOverview: {
    id: 'Unknown.AppNavigation.brandOverview',
    defaultMessage: 'Brand overview',
  },
  backofficeUsers: {
    id: 'Unknown.AppNavigation.backofficeUsers',
    defaultMessage: 'Backoffice users',
  },
  performance: {
    id: 'Unknown.AppNavigation.performance',
    defaultMessage: 'Performance',
  },
  security: {
    id: 'Unknown.AppNavigation.security',
    defaultMessage: 'Security',
  },
  systemLogs: {
    id: 'Unknown.AppNavigation.systemLogs',
    defaultMessage: 'System logs',
  },
  myMarkets: {
    id: 'Unknown.AppNavigation.myMarkets',
    defaultMessage: 'My Markets',
  },
  hideMenu: {
    id: 'Unknown.AppNavigation.hideMenu',
    defaultMessage: 'Hide menu',
  },
  brandOwners: {
    id: 'Unknown.AppNavigation.brandOwners',
    defaultMessage: 'Brand owners',
  },
  systemAdmins: {
    id: 'Unknown.AppNavigation.systemAdmins',
    defaultMessage: 'System admins',
  },
  eventHistoryPL: {
    id: 'Unknown.AppNavigation.eventHistoryPL',
    defaultMessage: 'P/L event history',
  },
});
