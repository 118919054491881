import sortArray from 'sort-array';
import { BetfairExchangeBetStatus, BetfairExchangeSize } from '../../types';
import { ExchangeBetStatus } from './hooks/useExtendedExchangeBetStatusMap';

interface CreateExchangeBetStatusParams {
  betfairExchangeBetStatus: BetfairExchangeBetStatus;
  isClosed: boolean;
  betfairExchangeSizes: BetfairExchangeSize[];
}

const createExchangeBetStatus = (
  params: CreateExchangeBetStatusParams,
): ExchangeBetStatus => {
  const { isClosed, betfairExchangeBetStatus, betfairExchangeSizes } = params;

  if (isClosed) return 'settled';

  const hasCancelledSize = betfairExchangeSizes?.some(
    (size) => size.type === 'cancelled' && size.size > 0,
  );
  if (hasCancelledSize) return 'cancelled';

  if (betfairExchangeBetStatus === 'execution_complete') return 'matched';

  if (betfairExchangeBetStatus === 'expired') return 'voided';

  const sortedSizeTypes = sortArray(betfairExchangeSizes || [], {
    by: 'size',
    order: 'desc',
  });

  return sortedSizeTypes?.[0]?.type || 'unmatched';
};

export default createExchangeBetStatus;
