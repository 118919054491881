import { GridApiPremium, UIContext } from '@miyagami-com/lsx-ui-components';
import { GridEventListener, GridRowId } from '@mui/x-data-grid-premium';
import { useCallback, useContext, useState } from 'react';
import convertSportLineRowToExchangeConfig from './convertSportLineRowToExchangeConfig';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../../../common/constants';
import { ExchangeConfig } from '../../../../types';
import { useIntl } from 'react-intl';
import messages from './messages';
import usePlatformForceCache, {
  ForceUpdateCacheParams,
} from '../../../common/hooks/usePlatformForceCache';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { isInstanceOfGridCellEditCommitParams } from '../../../common/isInstanceOf';
import { SportLineRow } from './useColumns';

interface UseOnCellEditCommitParams {
  playerId?: string;
  brandId: string;
  apiRef: React.MutableRefObject<GridApiPremium>;
}

type UpdateExchangeConfigParams = {
  exchangeConfig: Omit<ExchangeConfig, 'id' | 'disabled_by'>;
};

type AlertSeverity = 'success' | 'error' | 'warning' | 'info';

const useOnCellEditCommit = ({
  playerId,
  brandId,
  apiRef,
}: UseOnCellEditCommitParams): {
  isLoading: boolean;
  onCellEditCommit: GridEventListener<'cellEditCommit'>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const firebase = useFirebaseApp();
  const forceCache = usePlatformForceCache({ brandId });
  const { setAlert } = useContext(UIContext);
  const intl = useIntl();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const showAlert = useCallback(
    (message: string, severity: AlertSeverity) => {
      setAlert({
        message,
        show: true,
        severity,
      });
    },
    [setAlert],
  );

  const checkForDuplicatePriority = useCallback(
    (value: number, currentRowId: GridRowId): boolean => {
      if (value === 0) return false;

      const allRows = apiRef.current.getRowModels();
      return Array.from(allRows.entries()).some(
        ([rowId, row]) =>
          rowId !== currentRowId && (row as SportLineRow).priority === value,
      );
    },
    [apiRef],
  );

  const handleUpdateExchangeConfig = useCallback(
    async (sportLineRow: SportLineRow) => {
      const exchangeConfig = convertSportLineRowToExchangeConfig({
        sportLineRow,
        playerId,
        brandId,
      });

      const updateExchangeConfig = httpsCallable<
        UpdateExchangeConfigParams,
        void
      >(functions, 'back-exchangeConfig-updateExchangeConfig');

      await updateExchangeConfig({ exchangeConfig });

      const cacheParams: ForceUpdateCacheParams[] = [
        { type: 'exchangeConfig' },
        { type: 'exchangeNavigationNode' },
      ];

      await forceCache(cacheParams);
    },
    [brandId, forceCache, functions, playerId],
  );

  const validateAndHandlePriorityUpdate = useCallback(
    async (value: number, rowId: GridRowId) => {
      if (checkForDuplicatePriority(value, rowId)) {
        throw new Error('duplicate_priority');
      }
    },
    [checkForDuplicatePriority],
  );

  const onCellEditCommit: GridEventListener<'cellEditCommit'> = useCallback(
    async (params) => {
      if (isLoading || !isInstanceOfGridCellEditCommitParams(params)) {
        return;
      }

      setIsLoading(true);
      try {
        const { field, value, id } = params;

        // Validate priority updates
        if (field === 'priority') {
          await validateAndHandlePriorityUpdate(Number(value), id);
        }

        // Update the exchange config
        const beforeUpdateRow = apiRef.current.getRow(id) as SportLineRow;
        const sportLineRow = {
          ...beforeUpdateRow,
          [field]: value,
        };

        await handleUpdateExchangeConfig(sportLineRow);
        showAlert(intl.formatMessage(messages.successUpdate), 'success');
      } catch (error) {
        const errorMessage =
          error instanceof Error && error.message === 'duplicate_priority'
            ? messages.duplicatePriority
            : messages.errorUpdate;
        showAlert(intl.formatMessage(errorMessage), 'error');
      } finally {
        setIsLoading(false);
      }
    },
    [
      apiRef,
      handleUpdateExchangeConfig,
      intl,
      isLoading,
      showAlert,
      validateAndHandlePriorityUpdate,
    ],
  );

  return { isLoading, onCellEditCommit };
};

export default useOnCellEditCommit;
